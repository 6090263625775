import {ConfigProvider} from "antd";
import {memo, Suspense} from "react";
import Loading from "../shared-components/loading/Loading";
import Routes from "../routes";

const Layouts = () => {
    return (
        // TODO locale={currentAppLocale.antd}
        // TODO theme={lightTheme}
        <ConfigProvider>
            <Suspense fallback={<Loading cover={"content"}/>}>
                <Routes/>
            </Suspense>
        </ConfigProvider>
    )
}

export default memo(Layouts);