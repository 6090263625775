const API_URL = window._env_.API_URL

const dev = {
    API_ENDPOINT_URL: API_URL ?? '/api'
};

const prod = {
    API_ENDPOINT_URL: API_URL ?? '/api'
};

const test = {
    API_ENDPOINT_URL: API_URL ?? '/api'
};

const local = {
    API_ENDPOINT_URL: 'http://localhost:8080/api'
};

const getEnv = () => {
    switch (process.env.NODE_ENV) {
        case 'local':
            return local
        case 'development':
            return dev
        case 'production':
            return prod
        case 'test':
            return test
        default:
            break;
    }
}

export const env = getEnv()