import {useRecoilState, useRecoilValue} from "recoil";
import {authToken} from "../recoil/atoms";
import {Navigate, Outlet, redirect, useLocation} from "react-router-dom";
import {AUTH_PREFIX_PATH, REDIRECT_URL_KEY, UNAUTHENTICATED_ENTRY} from "../configs/AppConfig";
import {useEffect} from "react";


const ProtectedRoute = () => {
    const token = useRecoilValue(authToken)
    const location = useLocation()

    if (!token) {
        return <Navigate replace to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}/>
    }


    return <Outlet/>
}

export default ProtectedRoute