import React from 'react';
import './App.css';
import {RecoilRoot} from "recoil";
import {BrowserRouter} from "react-router-dom";
import Layouts from "./layouts";

function App() {
    return (
        <RecoilRoot>
            <BrowserRouter>
                <Layouts/>
            </BrowserRouter>
            {/*<Main/>*/}
        </RecoilRoot>
    );
}

export default App;
