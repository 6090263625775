import React, {CSSProperties, HTMLAttributes} from 'react';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const Icon = <LoadingOutlined style={{fontSize: 35}} spin/>

type Cover = "content" | "page" | "inline"


interface LoadingWrapperProps extends HTMLAttributes<HTMLDivElement> {
    cover: Cover
}

const LoadingWrapper = (props: LoadingWrapperProps) => {

    let style: CSSProperties | undefined = undefined;
    switch (props.cover) {
        case "content":
            style = {
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)"
            };
            break
        case "page":
            style = {
                position: "fixed",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }
            break
    }

    return (
        <div className={props.className} style={style}>
            {props.children}
        </div>
    )
}


interface LoadingProps {
    align: string,
    cover: Cover
}

const Loading = (props : LoadingProps) => {
    const {align, cover} = props
    return (
        <LoadingWrapper className={`${align ? `text-${align}` : ''}`} cover={cover}>
            <Spin indicator={Icon}/>
        </LoadingWrapper>
    )
}
Loading.defaultProps = {
    align: 'center',
    cover: 'inline'
}

export default Loading;