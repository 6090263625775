import React from "react";
import {RouteDefinition} from "../routes";
import {AUTH_PREFIX_PATH} from "./AppConfig";


export const publicRoutes: RouteDefinition[] = [
    {
        key: 'planer',
        path: '/planer',
        component: React.lazy(() => import('../components/PlanerView'))
    },
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('../views/auth-views/authentication/login'))
    }
]

export const protectedRoutes: RouteDefinition[] = [
    {
        key: 'content-manger',
        path: '/content-manager',
        component: React.lazy(() => import('../views/app-views/content-manager')),
        subRoutes: [
            {
                isIndex: true,
                key: 'content-list',
                path: 'content-list',
                component: React.lazy(() => import('../views/app-views/content-manager/content-list'))
            },
            {
                key: 'add-content',
                path: 'add-content',
                component: React.lazy(() => import('../views/app-views/content-manager/add-content'))
            },
            {
                isIndex: true,
                key: 'default',
                path: '*',
                component: React.lazy(() => import('../views/app-views/content-manager/content-list'))
            },
        ]
    }
]