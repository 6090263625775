import {atom, atomFamily, selector} from "recoil";
import {AUTH_TOKEN} from "../constants/AuthConstant";
import {localStorageEffect} from "./effects";

export const itemIdsState = atom({
    key: 'itemIdsState',
    default: [],
})

export const authToken = atom({
    key: 'authState',
    default: null as string | null,
    effects: [
        localStorageEffect(AUTH_TOKEN)
    ]
})


export const searchState = atom({
    key: 'searchState',
    default: null as string | unknown
})


export const addAssetFileFamily = atomFamily({
    key: 'addAssetFile',
    default: {}
})