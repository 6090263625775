import {Routes as RouterRoutes, Navigate, Route} from 'react-router-dom'
import {protectedRoutes, publicRoutes} from "../configs/RouteConfig";
import PublicRoute from "./PublicRoute";
import {LazyExoticComponent} from "react";
import ProtectedRoute from "./ProtectedRoute";
import AppRoute from "./AppRoute";

export interface RouteDefinition {
    isIndex?: boolean | undefined
    key: string
    path: string | undefined
    component: LazyExoticComponent<any>
    subRoutes?: RouteDefinition[] | undefined
}

const Routes = () => {


    const renderRoute = (route: RouteDefinition, index: number) => {
        if (route.isIndex) {
            return (
                <Route
                    index
                    key={route.key + index}
                    path={route.path}
                    element={<AppRoute component={route.component}/>}
                />
            )
        }

        return (
            <Route
                key={route.key + index}
                path={route.path}
                element={<AppRoute component={route.component}/>}
            >
                {route.subRoutes?.map(renderRoute)}
            </Route>
        )
    }


    return (
        <RouterRoutes>
            <Route path={"/"} element={<PublicRoute/>}>
                <Route path="/" element={<Navigate replace to={"/planer"}/>}/>
                {publicRoutes.map(renderRoute)}
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Route>

            <Route path={"/"} element={<ProtectedRoute/>}>
                {protectedRoutes.map(renderRoute)}
            </Route>

        </RouterRoutes>
    )
}

export default Routes